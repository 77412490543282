<template>
  <div class="row">
    <div class="col-md-12">
      <h3>{{ step.title }}</h3>
      <p v-if="step.description" class="step-description"></p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- delivery start  -->
        <div class="card mb-2" v-if="montageGroup">
          <div class="card-body">
            <div class="row">
              <div
                v-for="(item, key, index) in montageGroup" :key="key"
                :class="[index === 0 ? 'col-md-5' : 'col-md-6 mx-2']"
              >
                <div class="form-check">
                  <input v-if="!item.disabled" v-model="item.selected" type="checkbox" class="form-check-input"
                         :id="`custom-check${item.id}`" @click="checkOptionMontage(index, item)">
                  <label class="form-check-label" :class="{ 'disabled': item.disabled }"
                         :for="`custom-check${item.id}`">
                    <strong>{{ item.name }}</strong>
                  </label>
                </div>
                <template v-if="index === 1">
                  <input
                    @input="getPriceByCode($event, item)"
                    v-model="deliveryCode"
                    type="number"
                    class="form-control"
                    placeholder="Postleitzahl eingeben"
                    :class="{'is-invalid': inputMessage && inputMessage.status, 'is-valid': inputMessage && !inputMessage.status}"
                    :disabled="disabledInput()">
                  <div v-if="inputMessage && inputMessage.status" class="invalid-feedback">
                    {{ inputMessage.message }}
                  </div>
                  <div v-if="inputMessage && !inputMessage.status"
                       class="valid-feedback">
                    {{ inputMessage.message }}
                  </div>
                </template>
                <div class="card-block">
                  <div class="card-title">
                    <h6>
                      <tooltip v-if="item.tooltip.text || item.tooltip.image" style="display: inline-block"
                               :tooltip="item.tooltip"><i class="fa fa-info-circle text-primary ml-2"></i></tooltip>
                    </h6>
                    <strong v-if="index === 1" v-html="item.print_description" class="text-danger"></strong>
                    <p v-else v-html="item.print_description"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- delivery end  -->
        <div class="card mb-2" v-for="(item, index) in optionsMapGroup" :key="index">
          <div class="card-body">
            <div class="row d-flex align-items-center">
              <div class="col-md-3 ml-3">
                <div class="card-block">
                  <div class="card-title">
                    <h6>
                      <strong>{{ item.name }}</strong>
                      <tooltip v-if="item.tooltip.text || item.tooltip.image" style="display: inline-block"
                               :tooltip="item.tooltip"><i class="fa fa-info-circle text-primary ml-2"></i></tooltip>
                    </h6>
                    <p v-html="item.print_description"></p>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div v-if="item.type === 'check'" class="form-check">
                  <input v-if="!item.disabled" v-model="item.selected" type="checkbox" class="form-check-input"
                         :id="`custom-check${item.id}`" @click="checkOption(item)">
                  <label class="form-check-label text-danger" :class="{ 'disabled': item.disabled }"
                         :for="`custom-check${item.id}`">
                    <span v-if="item.children.length < 0">{{ $filters.currency(item.price.self) }}</span>
                  </label>
                </div>
              </div>
              <div class="col-md-5" :class="{ 'disabled': !item.selected, '': item.selected }">
                <table v-if="item.children.length > 0" class="table table-borderless">
                  <template v-for="(child, idx) in item.children" :key="idx">
                    <tr v-if="child.visible">
                      <td>
                        <h6>
                          <strong>{{ child.name }}</strong>
                          <tooltip v-if="child.tooltip.text || child.tooltip.image" style="display: inline-block"
                                   :tooltip="child.tooltip"><span class="fa fa-info-circle text-primary"></span>
                          </tooltip>
                        </h6>
                        <p v-html="child.print_description"></p>
                      </td>
                      <td class="text-right">
                        <div v-if="child.type === 'check'" class="form-check">
                          <input v-if="!child.disabled" v-model="child.selected" type="checkbox"
                                 class="form-check-input" :id="`custom-check${child.id}`" @click="checkOption(child)">
                          <label class="form-check-label text-danger" :class="{ 'disabled': child.disabled }"
                                 :for="`custom-check${child.id}`">{{ $filters.currency(child.price.self) }}</label>
                        </div>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table class="table">
        <tfoot>
        <tr>
          <td><strong>Gesamtmontage</strong></td>
          <td colspan="2"><span class="text-danger">{{ $filters.currency(total) }}</span></td>
        </tr>
        <!--<tr>
            <td colspan="1">
                <strong>Bonus für elektrischen Antrieb</strong>
                <tooltip style="display: inline-block" :tooltip="{ text: 'Dieser Bonus ist nur gültig wenn Sie ein elektrisches Antrieb und eine Komplettmontage kaufen.', image: null }"><span class="fa fa-info-circle text-primary"></span></tooltip>
            </td>
            <td>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="custom-check-bonus" @click="checkBonus" v-if="bonusActive">
                    <label class="custom-control-label text-danger" for="custom-check-bonus" :class="{ 'disabled': !bonusActive, '': bonusActive }">-{{ 300 | currency }}</label>
                </div>
            </td>
            <td>Extra Rabatt von 300€ bei Auswahl von elektrischen Antrieb und eine Komplettmontage.</td>
        </tr>-->
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'

export default {
  setup () {
    const bonus = ref(300)
    const bonusActive = ref(false)
    const type = ref(null)
    const deliveryCode = ref(null)
    const inputMessage = ref(null)
    const t = 'Montage in der Region'
    const store = useStore()

    const optionsMapGroup = computed(() => {
      const map = {}
      let id = 0
      const options = store.getters['configurator/currentStep'].options
      options.forEach(option => {
        if (option.group !== 'montage') {
          if (option.group === 'default') {
            option.children = []
            id = option.id
            map[option.id] = option
          }
          if (option.group === 'child') {
            map[id].children.push(option)
          }
        }
      })
      return map
    })
    const montageGroup = computed(() => {
      const map = {}
      const options = store.getters['configurator/currentStep'].options
      options.forEach(option => {
        if (option.group === 'montage') {
          map[option.id] = option
        }
      })
      return map
    })
    const total = computed(() => {
      let price = 0
      store.getters['configurator/currentStep'].options.filter(o => {
        if (o.selected && !o.disabled) {
          price += o.price.self
        }
      })
      return price
    })
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const deliveryCodes = computed(() => store.getters['configs/configs'])

    const region = computed(() => {
      const el = store.getters['configs/region']
      if (typeof el !== 'undefined') {
        return el
      }
      return ''
    })

    // methods
    function checkOption (option) {
      bonusActive.value = false
      store.dispatch('configurator/toggleOption', option)
      const step = store.getters['configurator/currentStep'].options.filter(o => o.selected && o.bonus)
      if (step.length > 0) {
        bonusActive.value = true
      }
    }

    async function checkOptionMontage (index, option) {
      if (index === 0) {
        deliveryCode.value = null
        inputMessage.value = null
        // uncheck selected options
        unCheckOptions()
        updatePrices(0)
      } else {
        const el = deliveryCodes.value.find((item) => Number.parseInt(item.country_code) === 1)
        if (typeof el !== 'undefined') {
          deliveryCode.value = 10115
          await getRegionByCode(deliveryCode.value)
          changeTitle(option, region.value?.region)
          validateInput(option, deliveryCode.value)
          updatePrices(el.price)
        }
      }
      bonusActive.value = false
      await store.dispatch('configurator/selectOption', option)
      const step = store.getters['configurator/currentStep'].options.filter(o => o.selected && o.bonus)
      if (step.length > 0) {
        bonusActive.value = true
      }
    }

    function unCheckOptions () {
      Object.keys(optionsMapGroup.value).forEach((key) => {
        optionsMapGroup.value[key].selected = false
        optionsMapGroup.value[key].children.forEach((item) => {
          item.selected = false
        })
      })
    }

    function updatePrices (percentage) {
      store.dispatch('configurator/updateOptionsMontagePrice', percentage)
    }

    function changeTitle (option, regionName) {
      if (option?.name) {
        const newTitle = regionName ? ':' + regionName : ''
        option.name = t + newTitle
      }
    }

    async function getPriceByCode (e, option) {
      const value = e.target.value.charAt(0)
      const code = e.target.value
      await getRegionByCode(code)

      const el = deliveryCodes.value.find((item) => item.country_code === value)
      // change label name
      if (typeof el !== 'undefined') {
        changeTitle(option, region.value?.region)
      } else {
        changeTitle(option, '')
      }
      // update price
      if (region.value?.region) {
        updatePrices(el.price)
      } else {
        option.price.self = 0
        updatePrices(0)
      }
      validateInput(option, value)
      await store.dispatch('configurator/selectOption', option)
    }

    function validateInput (option, value) {
      let defineObj = {}
      if (region.value?.region) {
        defineObj = { status: false, message: region.value.region }
      } else {
        defineObj = { status: true, message: 'Postleitzahl nicht gefunden' }
      }
      if (value === '') {
        changeTitle(option, '')
        defineObj = { status: true, message: 'Geben Sie Ihre Postleitzahl ein' }
      }
      inputMessage.value = defineObj
    }

    function disabledInput () {
      if (montageGroup && montageGroup.value && typeof montageGroup.value === 'object') {
        const firstEntry = Object.entries(montageGroup.value)[0]
        if (firstEntry) {
          const [key, value] = firstEntry
          if (value && value.selected) {
            return true
          }
        }
      }
      return false
    }

    function checkBonus (e) {
      const checked = e.target.checked
      if (checked) {
        store.dispatch('configurator/setMontageBonus', -300)
      } else {
        store.dispatch('configurator/setMontageBonus', 0)
      }
    }

    async function getRegionByCode (code) {
      await store.dispatch('configs/getRegionByCode', code)
    }

    return {
      bonus,
      bonusActive,
      type,
      optionsMapGroup,
      total,
      step,
      montageGroup,
      deliveryCode,
      inputMessage,
      region,
      checkOptionMontage,
      getPriceByCode,
      disabledInput,
      checkOption,
      checkBonus
    }
  }
}
</script>
