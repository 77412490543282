<template>
  <div v-if="!previewImagesLoading" class="configurator-preview" :class="{ 'falttor-box': dimensionBox }" @mouseover="animate = true" @mouseout="animate = false">
    <PreviewImage v-for="(image, index) in images"
    :key="index"
    :image="image"
    :animate="animate"
    :width="dimensions.width"
    :height="dimensions.height"/>
    <div class="btn-abs">
      <button
      class="btn-reset"
      data-bs-toggle="modal"
      data-bs-target="#previewImageModal"
      @click="showModal"
    >
      <i class="fas fa-expand"></i>
    </button>
    <button
      v-if="threed"
      class="btn-reset ml-2"
      data-bs-toggle="modal"
      data-bs-target="#preview3dModal"
      @click="showThreedModal"
    >
      <img src="@/assets/img/3d-rotate-icon.png" class="threed-icon"/>
    </button>
    </div>

  </div>
  <spiner size="5" color="text-primary" v-else/>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import PreviewImage from '~/pages/configurator/common/previewImage'
import { useRoute } from 'vue-router'
export default {
  name: 'PreviewImages',
  components: {
    PreviewImage
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const animate = ref(false)
    const dynHeight = ref({ height: 320 + 'px' })
    const previewImagesLoading = computed(() => {
      return store.getters['configurator/previewImagesLoading']
    })
    const images = computed(() => {
      return store.getters['configurator/previewImages']
    })
    const threed = computed(() => {
      return store.getters['configurator/threed']
    })
    const dimensionBox = computed(() => {
      if (route.params.name && route.params.name === 'falttor') {
        return true
      }
      return false
    })
    const dimensions = computed(() => {
      if (store.getters['configurator/config']) {
        const dims = store.getters['configurator/config']
        if (dims && typeof dims.width !== 'undefined') {
          if (dims.width === 0 && dims.height === 0) {
            dynHeight.value = { height: 320 + 'px' }
            return { width: 730 + 'px', height: 320 + 'px' }
          } else {
            dynHeight.value = { height: dims.height + 'px' }
            return { width: dims.width + 'px', height: dims.height + 'px' }
          }
        } else {
          dynHeight.value = { height: 320 + 'px' }
          return { width: 730 + 'px', height: 320 + 'px' }
        }
      }
    })
    function showModal () {
      store.commit('configurator/SET_PREVIEW_MODAL_SHOW', true)
    }
    function showThreedModal () {
      store.commit('configurator/SET_PREVIEW_THREED_MODAL_SHOW', true)
    }
    return {
      animate,
      images,
      threed,
      previewImagesLoading,
      dimensions,
      dimensionBox,
      showModal,
      showThreedModal
    }
  }
}
</script>
<style scoped>
.falttor-box {
  height: 560px!important;
}
.falttor-box img{
  width: 686px;
  height: 100%;
  object-fit: none;
}
.btn-abs {
  position: absolute;
  right: 0;
  top: 5px;
}
.ml-2 {
  margin-left: 15px;
}
.threed-icon {
  top: 0px;
  width: 40px;
}
</style>
